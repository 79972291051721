<template>
  <div>
    <v-toolbar flat>
      <!-- <v-toolbar-title>{{ $t('title.permission_list') }}</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        elevation="5"
        @click="dialogDelete = !dialogDelete"
        :disabled="dataDelete.length < 1"
        v-if="checkUserPermission('DELETE')"
        >{{ $t("button.delete_all") }}</v-btn
      >
      <v-btn
        class="ml-5"
        elevation="5"
        color="primary"
        @click="fetchPopUp(true)"
        v-if="checkUserPermission('CREATE', userPermission)"
        >{{ $t("button.create") }}</v-btn
      >
      <dialog-delete
        :dialog-delete="dialogDelete"
        :darkmode="darkmode"
        v-on:closeDelete="closeDelete"
        v-on:deleteItemConfirm="deleteItemConfirm"
      />
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogDelete from "../../../components/shared/notify/DialogDelete";
export default {
  components: { DialogDelete },
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async deleteItemConfirm() {
      await this.deletePermission({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      this.fetchListPermission(this.params);
      this.dialogDelete = false;
    },
    ...mapActions("$_permission", [
      "fetchPopUp",
      "fetchListPermission",
      "deletePermission",
    ]),
  },
};
</script>

<style lang="scss" scoped>
</style>
